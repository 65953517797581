.workflow {
    width: 100%;
}

.workflowInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    min-height: 40px;
    padding: 10px 20px;
    border-radius: 6px;
    /* background-color: #55c2a0; */
    border: 2px solid #3EB1C8;
    align-items: center;
    gap: 30px;
}

.workflowInfo .arrowRight.active {
    transform: rotate(90deg);
    transition: all .3s;
}

/* NAME */
.workflowNameWrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    flex: 1;
}

.workflowName {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
}

.workflowNameAction {
    display: flex;
    align-items: center;
    gap: 5px;
}

/* PROCESS */
.processWrapper {
    flex: 2;
    display: flex;
    gap: 10px;
    padding: 10px 20px;
}

.processLineWrapper {
    display: flex;
    gap: 10px;
    flex: 2;
    align-items: center;
}

/* ACTIONS */
.workflowInfo[disabled] path {
    stroke: lightgray;
}

.workflowInfo .play-icon {
    color:  rgb(4, 139, 46);
}
.workflowInfo .play-icon path {
    stroke: rgb(222, 129, 245);
    stroke-width: 3px;
}

.workflowAction {
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 1;
    justify-content: flex-end;
}

.workflowAction .removeWorkflowButton {
    display: flex;
    outline: none !important;
    border: none !important;
    justify-content: center;
    justify-items: center;
    text-align: center;
    background-color: transparent;
    color: red;
    box-shadow:none !important;
    padding: 0;
}

.workflowAction .removeWorkflowButton i {
    line-height: unset;
    font-size: 22px;
}

@media screen and (max-width: 860px) {
    .workflowInfo {
        flex-wrap: wrap;
        gap: 10px;
    }

    .workflowInfo .workflowNameWrapper,
    .workflowInfo .workflowAction {
        order: 2;
    }

    .workflowInfo .processLineWrapper {
        flex: unset;
        width: 100%;
    }
}

.workflowBody {
    width: calc(100% - 20px);
    margin-left: auto;
    border: 2px solid #3EB1C8;
    position: relative;
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.workflowBody::before {
    content: "";
    width: calc(100% + 2px);
    height: 10px;
    background-color: white;
    border-right: 2px solid #3EB1C8;
    position: absolute;
    top: 0;
    right: -2px;
    z-index: 2;
    transform: translateY(-4px);
}