.root {
  align-items: center;
  display: flex;
  padding-inline-end: 8px;
  gap: 4px;
}

.expandIconWrapper {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  height: 24px;
  justify-content: center;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.expandIconWrapper:not(.isHasChild) {
  width: 10px;
}

.expandIconWrapper.isHasChild {
  width: 20px;
}

.labelGridItem {
  padding-inline-start: 2px;
}

.inputWrapper {
  display: flex;
  flex-direction: row; /* Place text and buttons side by side */
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
  align-items: center; /* Vertically center items */
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.inputSubWrapper {
  display: flex;
  gap: 5px;
}

.textField input {
  padding: 3px;
}

.editButton {
  padding: 6px;
}

.editIcon {
  font-size: 15px;
}

.nodeInput {
  width: 74%;
}

.nodeLabel {
  flex: 1;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 0;
  text-align: left;
  margin-bottom: 0;
  cursor: pointer;
}
