.images-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.image-holder {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.image-box {
    align-self: center;
    min-width: 300px;
    width: 100%;
    border-radius: 6px;
    padding: 3px;
    margin: 0 10px;
    border: 1px solid #222;
    overflow: hidden;
    background-color: lightgray;
    position: relative;
    max-width: 700px;
}

.gridIOTab .image-box {
    max-width: 500px;
} 

@media screen and (min-width: 1900px) {
    .gridIOTab .image-box {
        max-width: 700px;
    } 
}

.image-box::before {
    content: '';
    padding-top: 100%;
    display: block;
    position: relative;
    z-index: 1;
}

@media screen and (max-width: 1700px) {
    .image-box {
        max-width: 500px;
    }
}

@media screen and (max-width: 860px) {
    .images-container{
        flex-direction: column;
    }

    .image-box {
        max-width: none !important;
        width: 100%;
    }
}

.image-frame-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.image-frame {
    display: block;
    width: 100%;
    height: 100%;
    align-self: center;
    border-radius: 6px;
    overflow: hidden;
}

.image-frame[disabled]{
    align-self: center;
    background-color: darkslategray;
    opacity: 0.3;
    border-radius: 6px;
    overflow: hidden;
}

.react-transform-wrapper{
    width: 100% !important;
    height: 100% !important;
}

.react-transform-component{
    width: 100% !important;
    height: 100% !important;
}

.react-transform-element{
    width: 100% !important;
    height: 100% !important;
}

.image{
    width: 100%;
    margin-bottom: auto;
    /* height: 100%; */
    /* border: 1px solid green; */
}

.image[disabled]{
    width: 100%;
    height: 100%;
    opacity: 0.7;
}

.radio-holder{
    margin: 4px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-holder .radio{
    display: flex;
    align-items: center;
    margin: 0 4px;
    cursor: pointer;
}

.image-holder .inline-title{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}


.slider-holder{
    display: flex;
    position: absolute;
    border-radius: 5px;
    margin-top: 80px;
    min-height: 220px;
    height: 40vh;
    max-height: 22vw;
    height: max(min(22vw, 50vh), 200px);
    margin-left: 10px;
    padding: 15px 0;
    background-color: lightgray;
    opacity: 0.8;
    border: 2px solid black;
    z-index: 6;
}

@media screen and (min-width: 1800px) {
    .slider-holder {
        height: 25vh;
        max-height: 380px;
    }  
}

.zoom-buttons-holder{
    display: flex;
    position: absolute;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 10px;
    padding: 5px 4px;
    background-color: lightgray;
    opacity: 0.9;
    border: 2px solid black;
    z-index: 6;
}

.zoom-button{
    opacity: 1.0;
    background-color: rgb(165, 187, 226);
    border-radius: 5px;
    margin: 0 2px;
}

.zoom-button:hover{
    background-color: rgb(217, 227, 243);
    border-radius: 5px;
    margin: 0 2px;
}

.zoom-button:focus{
    outline: none;
}

.green-icon{
    color: green;
}
.green-icon path{
    stroke: green;
}

.red-icon{
    color: red;
}
.red-icon path{
    stroke: red;
}

.gray-icon{
    color: slategray;
}
.gray-icon path{
    stroke: slategray;
}

.lightgray-icon{
    color: lightgray;
}
.lightgray-icon path{
    stroke: lightgray;
}

.darkgray-icon{
    color: darkslategray;
}
.darkgray-icon path{
    stroke: darkslategray;
}

.blue-icon{
    color: blue;
}
.blue-icon path{
    stroke: blue;
}

/* IMAGE PREVIEW */
.imagePreviewWrapper {
    position: relative;
    margin-bottom: 10px;
    width: 100%;
}

.imagePreview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.imagePreviewWrapper .imagePreviewControl {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    border: none !important;
    outline: none !important;
    cursor: pointer;
    background-color: transparent;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.imagePreviewWrapper .imagePreviewControl:hover {
    font-weight: bold;
    color: white;
    background-color: #17a2b8;
    transition: all .2s;
}

.imagePreviewWrapper .imagePreviewControl.left {
    left: 20px;
}

.imagePreviewWrapper .imagePreviewControl.right {
    right: 20px;
}

.imagePreviewWrapper .orderImage {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
    font-weight: 600;
    opacity: .7;
}

.input-file .image-box{
    max-width: 600px;
}