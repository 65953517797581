@media (min-width: 992px) {
  .projects-container {
    margin: 0 10rem;
    /* border: 1px solid blue; */
  }
}

@media (max-width: 1000px) {
  .projects-container {
    width: max(100%, 330px);
  }
}

.projects-container .navbar {
  background-image: none;
  background-color: rgb(214, 217, 216);
  border-radius: 6px;
}

.filterBtn:not(:last-child) {
  margin-right: 5px;
}

.filterBtn:not(:hover) {
  background-color: white;
  color: #3EB1C8;
}

.stats {
  font-size: 12px;
  margin-left: 2px;
}

.projects-bar {
  margin: 10px 0;
}

.project-title {
  margin: 0 20px;
  color: black !important;
  font-size: 20px;
  font-weight: 700;

}

.project-nav-button {
  height: 34px;
  margin: auto 10px;
  padding: 0 10px;
}

.project-search {
  display: flex;
  margin: 0px 30px;
}

@media screen and (max-width:992px) {
  .project-search {
    width: 100%;
    margin: 15px 0 5px;
  }

  .project-search .project-search-text {
    flex: 1;
  }
}

.project-search-text {
  min-width: 10px;
  margin-right: 20px !important;
  border-radius: 5px;
}

.MuiInputBase-root  {
  background-color: aliceblue;
}

.grid-container {
  display: grid;
  grid-gap: 36px;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-auto-rows: minmax(320px, 1fr);
  margin: 40px 20px;
  justify-content: center;
  align-content: center;
}

.grid-container .card {
  display: flex;
  border: 1px solid lightgray;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  opacity: 1;
  background-color: #fefefe;
  transition: 0.5s all;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.grid-container .card:hover {
  border: 1px solid lightgray;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(38, 38, 38, 0.2);
  transform: translate(1px, -4px);
  background-color: white;
  overflow: hidden;
}

.grid-container .card:hover:before {
  content: attr(before-content);
  position: absolute;
  font-size: 20px;
  text-align: justify;
  color: white;
  z-index: 2;
  display: flex;
  padding: 6px;
  font-size: 14px;
  pointer-events: none;
  overflow: hidden;
}

.grid-container .card .card-img-holder {
  border-bottom: 1px solid lightgray;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  clip-path: inset(0);
}

.grid-container .card .card-img-holder:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: -16px;
  right: -16px;
  background: #3EB1C8;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1.5);
  transform-origin: 50% 50%;
  transition: transform 0.15s ease-out;
  opacity: 0.9;
}

.grid-container .card .card-img-holder:hover:before {
  transform: scale(20);
  opacity: 0.9;
}

.grid-container .card .card-img-holder .card-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.grid-container .card .card-body {
  border-bottom: 1px solid lightgray;
  padding: 10px 10px 15px 10px;
}

.grid-container .card .card-body .card-title {
  font-size: large;
  color: teal;
}

.grid-container .card .card-body .card-author {
  display: flex;
}

.grid-container .card .card-body .card-author-name:before {
  content: "by ";
  font-size: x-small;
  color: lightslategray;
  padding: 0 4px 0 0;
}

.grid-container .card .card-body .card-author-name {
  font-size: x-small;
  color: red;
}

.grid-container .card .card-stats {
  display: inline-block;
  text-align: left;
  margin: 4px 16px 4px 16px;
}

.grid-container .card .card-stats .card-stats-text {
  font-size: small;
  color: blue;
}

.rodal-dialog {
  border-radius: 8px;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 !important;
}

.newWindow .title {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: bold;
  color: #3EB1C8;
}

.newWindow .container {
  width: 100%;
  height: 70%;
  overflow: auto;
}
.newWindow .container .parameter {
  margin-bottom: 10px;
  width: 90%;
  margin: auto;
  padding: 4px 0 8px 0;
}

.newWindow .container .parameter .name {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 4px 6px 4px 4px;
  margin: 2px 0 2px 0;
}
.newWindow .container .parameter .name::placeholder {
  color: gray;
}

.newWindow .container .parameter .description {
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  max-height: 80px;
  padding: 4px 4px 4px 6px;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin: 2px 0 2px 0;
}
.newWindow .container .parameter .description::placeholder {
  color: gray;
}

.newWindow .container .parameter .custom-file {
  text-align: left;
}

/* .newWindow .container .parameter .custom-file .custom-file-input{
} */

.newWindow .container .parameter .custom-file .custom-file-label {
  color: gray;
  overflow: hidden;
}

.newWindow .container .parameter .custom-users {
  text-align: left;
}

.newWindow .container .parameter .checkbox-holder {
  width: 90%;
  justify-content: left;
  display: inline-flex;
  margin: 10px;
}

.checkbox-holder .checkbox {
  margin-left: 20px;
}

.newWindow .container .parameter .checkbox-holder .custom-control-label {
  font-size: 16px;
  margin: 0 4px 0 4px;
}

.newWindow .button-holder {
  margin: 20px 0 0 0;
}

.newWindow .button-holder .button {
  margin: 0 15px 0 15px;
}

.btn-search{
  color: #3EB1C8 !important;
  border: 1px solid #3EB1C8;
  background-color: transparent !important;
}

.btn-search:hover{
  background-color: #3EB1C8 !important;
  color: #FFFFFF !important;
  border: 1px solid #3EB1C8;
}

.btn-new-project{
  background-color: #3EB1C8;
  color: #FFFFFF;
  border: 1px solid #3EB1C8;
}

.btn-new-project:hover{
  background-color: #FFFFFF;
  color: #3EB1C8;
  border: 1px solid #3EB1C8;
}

button.btn-new-project:active{
  background-color: #FFFFFF !important;
  color: #3EB1C8 !important;
  border: 1px solid #3EB1C8 !important;
}

button.btn-new-project:focus{
  box-shadow: 0 0 0 1px #3EB1C8 !important;
  opacity: 0.8;
}

.title-new-project{
  color: #00B388;
}

.button-ok{
  background-color: #3EB1C8;
  color: #FFFFFF;
  border: 1px solid #3EB1C8;
}

.button-ok:hover{
  background-color: #FFFFFF;
  color: #3EB1C8;
  border: 1px solid #3EB1C8;
}

button.button-ok:active{
  background-color: #FFFFFF !important;
  color: #3EB1C8 !important;
  border: 1px solid #3EB1C8 !important;
}

button.button-ok:focus{
  box-shadow: 0 0 0 1px #3EB1C8 !important;
  opacity: 0.8;
}
/* Review image from the input file */
.imageReviewWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  margin: 2px 0 2px 0;
  border-radius: 4px;
  overflow: hidden;
}

.imageReviewWrapper img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
  object-fit: contain;
  border-radius: 4px;
  overflow: hidden;
}
