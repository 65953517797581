.wrapper{
    position: fixed;
    right: 30px;
    bottom: 30px;
}

.floating-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #3EB1C8;
    opacity: 0.7;
    color: white;
    font-size: 30px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    outline: none !important;
    border: none !important;

    display: flex;
    justify-content: center;
    align-items: center;
}

.floating-button:hover{
    transform: scale(1.1);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}