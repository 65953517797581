.main-container{
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.main-image {
    position: static;
    width: 100%;
    height:100%;
    background-image: none;
    text-align: center;
}

.section-container {
    width: 100%;
    background-color: #3DB1C8;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    z-index: 2;
    -webkit-box-shadow: 1px 10px 11px 0px rgba(107,107,107,1);
-moz-box-shadow: 1px 10px 11px 0px rgba(107,107,107,1);
box-shadow: 1px 10px 11px 0px rgba(107,107,107,1);
}

.section-container .section-title {
    font-size: 30px;
    font-weight: 700;
    color: #FFFFFF;
    text-align: center;
}

.section-container .section-description {
    font-size: 22px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 5px;
}

.gallery-container {
    width: 100%;
    background-color: #595959;
    padding-top: 30px;
    padding-bottom: 40px;
}

.grid-gallery {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

@media screen and (max-width: 1024px) {
    .grid-gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 640px) {
    .grid-gallery {
        grid-column-gap: 5px;
        grid-row-gap: 10px;
    }
}

.grid-gallery .gallery-item {
    width: 100%;
}

.grid-gallery .gallery-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.container-cloud {
   width: 100%;
   padding-top: 40px;
   padding-bottom: 40px;
   position: relative;
   overflow: hidden;
   background: linear-gradient(90deg, #B1B3B6 0%, #F6F7F7 100%);
}

.cloud-grid {
    width: 100%;
    display: flex;
}

.cloud-image {
    flex-shrink: 0;
    width: 50%;
}

.cloud-description-container {
    width: 50%;
    flex-shrink: 0;
}

@media screen and (max-width: 1024px) {
    .cloud-grid {
        flex-direction: column;
        gap: 40px;
        align-items: center;
    }

    .cloud-image {
        width: 70%;
    }

    .cloud-description-container {
        width: 80%;
        align-self: flex-start;
    }
}

@media screen and (max-width: 768px) {
    .cloud-description-container {
        width: 90%;
    }

    .cloud-image {
        width: 100%;
    }
}


.cloud-description {
    width: 100%;
    position: relative;
    z-index: 2;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    filter: drop-shadow(-1px 6px 3px rgba(0,0,0,0.35));
    
}

.cloud-description::after {
    position: absolute;
    background-color: #FFFFFF;
    width: calc(100% + 100px);
    content: '';
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    clip-path: polygon(0% 0%, 100% 0, 93% 100%, 0 100%);
}

.cloud-description-text {
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    color: #5D5D5D;
}

.cloud-description-text:not(:first-child) {
    margin-top: 20px;
}

.contact-form {
    padding-top: 40px;
    padding-bottom: 100px;
    width: 100%;
    height: 100%;
    /* margin: 10px auto; */
    background-color:rgba(230, 235, 243, 0.4);
    text-align: center;
}

.container-contact {
    margin: 10px auto;
    max-width: 60vw;
    text-align: left;
    background-color: white;
    padding: 30px;
    padding-bottom: 20px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 15px 5px #504e4e; 
    box-shadow: 0px 0px 15px 5px #504e4e;
}

.small-text {
    color: #7c7979;
}

.contact-form-grid {
    display: -ms-grid;
    display: grid;
    margin-top: 40px;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.error{
    color: #bf1650;
}
  
.error::before {
    display: inline;
    content: "⚠ ";
}

.btnSubmitContact {
    background-color: #60abd5;
    padding: 0.5rem 1rem;
    color: white;
    font-weight: 600;
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border: none !important;
    outline: none !important;
    cursor: pointer;
}

.btnSubmitContact:hover {
    opacity: 0.9;
}


@media screen and (max-width: 767px) {
    .contact-form {
        padding: 40px 20px;
    }
}

@media screen and (max-width: 680px) {
    .container-contact {
        max-width: 80vw;
    }

    .contact-form-grid {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
    }
}

  
.footer-dark {
    width: 100%;
    padding: 50px 0;
    color: #f0f9ff;
    background-color: #282d32;
}

.footer-dark h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 16px;
}

.footer-dark ul {
    padding: 0;
    list-style: none;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 0;
}

.footer-dark ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.6;
}

.footer-dark ul a:hover {
    opacity: 0.8;
}

@media (max-width:767px) {
    .footer-dark .item:not(.social) {
        text-align: center;
        padding-bottom: 20px;
    }
}

.footer-dark .item.text {
    margin-bottom: 36px;
}

@media (max-width:767px) {
    .footer-dark .item.text {
        margin-bottom: 0;
    }
}

.footer-dark .item.text p {
    opacity: 0.6;
    margin-bottom: 0;
}

.footer-dark .item.social {
    text-align: center;
}

@media (max-width:991px) {
    .footer-dark .item.social {
        text-align: center;
        margin-top: 20px;
    }
}

.footer-dark .item.social>a {
    font-size: 20px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
    margin: 0 8px;
    color: #fff;
    opacity: 0.75;
}

.footer-dark .item.social>a:hover {
    opacity: 0.9;
}

.footer-dark .copyright {
    text-align: center;
    padding-top: 24px;
    opacity: 1.0;
    font-size: 14px;
    margin-bottom: 0;
    color: gray;
}

.footer-dark .copyright a{
    color: darkgray;
    text-decoration: none;
}

.footer-dark .copyright a:hover{
    color: white;
}

.footer-dark .copyright a:active{
    color: blue;
}
