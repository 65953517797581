.multiInputsProcess {
    width: 100%;
    display: flex;
    gap: 10px;
    /* overflow: auto; */
    overflow: hidden;
}

.multiInputsProcess .listImageWrapper {
    display: flex;
    flex-direction: column;
}

.multiInputsProcess .listImage {
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-right: 10px;
    border-right: 1.5px dashed #d7d7d7
}

.multiInputsProcess .imageItem {
    word-break: break-word;
    text-align: left;
    display: flex;
    gap: 6px;
    font-size: 16px;
    font-weight: 500;
}

.multiInputsProcess .imageItem svg {
    flex-shrink: 0;
    margin-top: 2px;
}

.multiInputsProcess .titleCol {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    padding: 10px;
    width: 100%;
}

.multiInputsProcess .imageItem:hover {
    color: #17a2b8;
    cursor: pointer;
}

.multiInputsProcess .processTableWrapper {
    flex: 1;
    overflow: auto;
    padding-bottom: 10px;
}

/* PROCESS TABLE */
.multiInputsProcessTable {
    width: 100%;
}

.multiInputsProcessTable .stickyLeft {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 2;
    padding-right: 10px;
}

@media screen and (max-width: 700px) {
    .multiInputsProcessTable .stickyLeft {
        position: relative;
    }    
}

.multiInputsProcessTable .layout_multiInputsProcessTable {
    width: 100%;
    display: grid;
    grid-template-columns: 270px 230px 230px 230px 230px 230px;
}

.multiInputsProcessTable .thead_multiInputsProcessTable {
    width: 100%;
}

.multiInputsProcessTable .tbody_multiInputsProcessTable {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 300px;
}

.multiInputsProcessTable .td_multiInputsProcessTable {
    width: 100%;
    padding: 10px;
}

.multiInputsProcessTable .td_multiInputsProcessTable:empty {
    padding: 0;
}

.multiInputsProcessTable .tr_multiInputsProcessTable {
    width: 100%;
}

.multiInputsProcessTable .thead_multiInputsProcessTable .td_multiInputsProcessTable {
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.multiInputsProcessTable .tbody_multiInputsProcessTable .tr_multiInputsProcessTable:not(:first-child) .td_multiInputsProcessTable {
    border-top: 1px solid #f0f0f0;
}

.multiInputsProcessTable .td_multiInputsProcessTable {
    border-right: 1px solid #f0f0f0;
}

.multiInputsProcessTable .tbody_multiInputsProcessTable .tr_multiInputsProcessTable:last-child .td_multiInputsProcessTable {
    border-bottom: 1px solid #f0f0f0;
}

/* END PROCESS TABLE */

/* RESULT TOOLS */
.multiInputsProcessTable .txtSubtitle {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
}

.processResultWrapper .processResult {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.processResult .outputName {
    word-break: break-word;
    text-align: left;
    font-size: 14px;
}

/* PARAMETER TOOLS */
.parameterTools { 
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.parameterToolsItem {
    width: 100%;
    display: flex;
    gap: 4px;
    justify-content: space-between;
    gap: 8px;
}

.parameterToolsItem .toolName {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    padding-top: 2px;
}

.parameterToolsItem .toolValue {
    font-size: 14px;
    text-align: left;
}

/* MODAL EDIT PARAMETER */
.titleSettingImage {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 5px;
}

.settingImageOptions {
    width: 100%;
    display: flex;
    flex-direction: column;
}