.App {
  text-align: center;
  height: 100%;
}

@media (max-width: 1000px) {
  .App {
    text-align: center;
    height: 100%;
    width: max(100%, 350px);
  }
}

/* .mainlogo{
  height: 24px;
  margin: 0 15px 0 20px;
} */

.mainlogo {
  height: 80px;
  margin: 0 15px;
  align-items: center;
  align-self: center;
  justify-self: center;
}

.mainbrand {
  font-size: 26px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 700;
}

.navbar {
  background-color: black;
  /* background-image: linear-gradient(to right, rgb(230, 234, 234, 0.9) 30%, rgba(103, 102, 102, 0.9) 70%); */
  /* background-image: url("./images/Banner_blank.png"); */
}

.navbar .navbar-brand {
  color: white;
}

nav .nav-link{
  font-size: 18px;
  color: white !important;
}

.Toastify__toast {
  border-radius: 8px;
}

.Toastify__toast-body {
  white-space: pre-line;
}

.draggingSource {
  opacity: 0.3;
}

.dropTarget {
  background-color: #e8f0fe;
}

.progress-bar.bg-success {
  background-color: #00B388 !important;
}

.containerApp {
  width: 100%;
  /* max-width: 1640px; */
  padding-right: 8px;
  padding-left: 8px;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Responsive the tabs in project details page  */
.toolTab {
  width: 100%;
  display: flex;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.toolTab .parametersWrapper {
  width: 380px;
  flex-shrink: 0;
}

@media screen and (max-width: 1350px) {
  #netex.toolTab {
    flex-direction: column !important;
    align-items: center;
    min-width: unset
  }
}

@media screen and (max-width: 1160px) {
  .toolTab {
    flex-direction: column;
    align-items: center;
    min-width: unset
  }
}

@media screen and (max-width: 860px) {
  .toolTab .parametersWrapper {
    width: 100%;
  }
}

.toolTab .toolResultWrapper {
  width: 100%;
}

.progress{
  margin-top: 12px;
}


@media screen and (max-width: 860px) {
  .App .navbar-nav.nav-config{
    flex-direction: row;
    justify-content: flex-end;

    margin-top: 12px;
  }
}


.navbar-light .navbar-toggler-icon {
  filter: brightness(0) invert(1);
}