.workflowBar {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.workflowBar:not(:empty) {
    margin-top: 20px;
    padding-bottom: 20px;
}