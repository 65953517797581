.text-3BC {
  color: #3EB1C8;
}

.btn-update{
  background-color: #3EB1C8;
  color: #FFFFFF;
  border: 1px solid #3EB1C8;
}

.btn-update:hover{
  background-color: #3EB1C8;
  color: #FFFFFF;
  border: 1px solid #3EB1C8;
}