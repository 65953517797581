.treeNodeImage {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    max-width: 450px;
}

.imageItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    width: 100%;
    gap: 3px;
    overflow: hidden;
}

.imageItemSelected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    gap: 3px;
    overflow: hidden;
}

.imageNameWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 5px;
    overflow: hidden;
}

.imageName {
    flex: 1;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    width: 100%;
}

.rightIconWrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 5px;
}

.rightIcon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightIcon>* {
    margin: 0;
    width: 100%;
}