.radio-holder {
  margin-bottom: 4px !important;
}

.crop-view {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.crop-image-list {
  margin-top: 20px !important;
  height: fit-content;
}

.subtitle {
  margin-top: 8px !important;
}
