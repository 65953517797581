.editRockType {
    padding-inline: 7px;
}

.container {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 16px;
}

.rockTypeMinMax {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.rockTypeInputContainer {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}

.rockTypeInput {
    border-color: #3eb1c8 !important;
    width: 80%;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 12px;
}

.inputNumber {
    border-color: #3eb1c8 !important;
}

.button {
    padding: 0.125rem 0.125rem;
}