.input-grid {
  display: flex;
  align-content: space-evenly;
  justify-content: space-evenly;
  gap: 20px;
}

@media screen and (max-width: 860px) {
  .input-grid {
    flex-direction: column;
    align-items: center;
  }
}

.centering {
  margin: 0 auto;
}

.input-files {
  display: block;
  width: 100%;
  max-width: 400px;
  width: min(100%, 400px);
  margin-top: 20px;
}

.input-upload {
  display: block;
  width: 330px;
  margin-top: 20px;
}

.input-upload-files {
  display: block;
  width: 320px;
  height: 200px;
  margin: 0 auto;
  align-content: center;
  position: relative;
  overflow: hidden;
}
.input-upload-files2 {
  display: block;
  margin: 0 auto;
  align-content: center;
  position: relative;
  overflow: hidden;
}

.input-upload-files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: all 0.2s ease-in-out;
  padding: 100px 20px 60px 50px;
  background-color: #fefefe;
  border-radius: 10px;
}

.input-upload-files input:hover {
  background-color: #f7f7f7;
}

.input-upload-files:after {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  width: 56px;
  height: 56px;
  content: "";
  background-image: url("../images/cloud_upload_icon_color_64px.png");
  /* https://icon-icons.com/icon/upload-cloud/102887 */
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}

.input-upload-files:before {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  right: 0;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #3EB1C8;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}


.parameters-holder.add-rock-type {
  margin-top: 24px;
}

.input-upload-files.add-rock-type {
  width: 90%;
  height: unset;
  padding-block: 10px;
}
.input-upload-files2.add-rock-type {
  width: 90%;
  height: unset;
  padding-top: 10px;
}

.input-upload-files.add-rock-type input {
  padding: 40px 20px 40px 50px;
  font-size: 13px;
}

.input-upload-files2.add-rock-type input {
  text-align: center; /* Centrado del valor que escribe el usuario */
}

.input-upload-files.add-rock-type label {
  width: 100%;
  right: 0;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #3EB1C8;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

/* .input-upload-files.add-rock-type input:hover {} */

.input-upload-files2.add-rock-type input::placeholder {
  color: #6c757d;
  text-align: center; /* Centrado del placeholder */
  opacity: 0.6;
}

.input-upload-files2.add-rock-type input::value {

  text-align: center; /* Centrado del placeholder */

}


.input-upload-files.add-rock-type:after {
  width: 32px;
  height: 32px;
  display: none;
}

.input-upload-files.add-rock-type:before {
  bottom: 24px;
  font-size: 14px;
  display: none;
}

.input-upload-sizes {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  /* border: 1px solid blue; */
}

.input-upload-sizes .label {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 14px;
  /* border: 1px solid green; */
}

.inline-holder .number {
  align-self: center;
  font-size: 14px;
  width: 60px;
  margin: 0 5px;
  border: 1px solid #2ea591;
  border-radius: 4px;
  padding: 2px;
}

.inline-holder .number-float {
  align-self: center;
  font-size: 14px;
  width: 60px;
  margin: 0 5px;
  border: 1px solid #2ea591;
  border-radius: 4px;
  padding: 2px;
}

.input-upload-sizes .dropdown-font {
  font-size: 14px;
  background: #3EB1C8;
  border-color: #3EB1C8;
  /* border: 2px solid red; */
}

.inline-holder {
  display: inline-flex;
  justify-content: center;
  align-self: center;
  align-content: center;
  text-align: center;
  margin: 4px 0;
  /* border: 1px solid red; */
}

.inline-holder .inline-element {
  margin: 0px 4px;
}

.inline-holder .inline-element.btn-size {
  background-color: #68D2DF;
  border-color: #68D2DF;
}

.inline-holder .inline-element.btn-type {
  background-color: #6ECEB2;
  border-color: #6ECEB2;
}

.checkbox-holder {
  display: flex;
  align-content: center;
  align-self: center;
  margin-left: 16px;
  /* border: 1px solid red; */
}

.checkbox-holder .custom-control {
  display: flex;
  align-content: center;
  align-self: center;
}

.checkbox-holder .custom-control-label {
  align-self: center;
  /* border: 1px solid yellowgreen; */
}

.input-upload-progress {
  margin: 6px auto;
  width: 94%;
}

.input-upload .btn-block {
  margin: auto;
  width: 98%;
  background-color: #00B388;
  border-color: #00B388;
}

.btn-block:not(.btn-warning) {
  background-color: #00B388;
  border-color: #00B388;
  opacity: 0.8;
}

.btn-block:not(.btn-warning):hover {
  background-color: #00B388;
  border-color: #00B388;
  opacity: 1;
}

.image-details-holder {
  display: flexbox;
  text-align: left;
  margin: 4px 4px;
  padding: 4px 10px;
  /* border: 1px solid red; */
}

.image-details-holder .inline-holder .label {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 14px;
  /* border: 1px solid green; */
}

.image-details-holder .inline-holder .text {
  align-self: center;
  font-size: 14px;
  width: 100%;
  margin: 0 5px;
  border: 1px solid #2ea591;
  border-radius: 4px;
  padding: 2px;

  margin-top: auto;
  margin-bottom: auto;
  font-size: 14px;
  /* border: 1px solid green; */
}

.parameters-holder .inline-holder {
  display: inline-flex;
  width: 100%;
  justify-content: left;
  vertical-align: middle;
  margin: 4px 0;
  /* border: 1px solid green; */
}

.right-holder {
  display: flexbox;
  margin-left: auto;
  margin-right: 4px;
}

.focus-red {
  color: blue;
}

.focus-red path {
  stroke: blue;
}

.focus-red[active="t"] {
  color: red;
}

.focus-red[active="t"] path {
  stroke: red;
}

.focus-red[active="f"] {
  color: gray;
}

.focus-red[active="f"] path {
  stroke: gray;
}