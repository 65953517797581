.pm-tool.domain.parameters-holder .inline-holder {
  justify-content: space-between;
  gap: 6px;
  padding-inline: 6px;
}

.pm-tool.domain.parameters-holder .inline-holder.cellsize {
  justify-content: flex-start
}

.pm-tool.domain.parameters-holder .label {
  margin: 0;
}