.project-container {
  display: block;
  margin: 0 2rem;
  padding-top: 20px;
}

.project-container .navbar {
  background-image: none;
  background-color: rgb(214, 217, 216);
  border-radius: 6px;
}

.nav-container .nav-link-div {
  align-self: center;
  margin: 0 10px;
  cursor: pointer;
  align-content: center;
  display: flex;
}

.nav-container .nav-link-div:hover,
.nav-container .nav-link-div:hover path {
  color: #3eb1c8 !important;
}

.nav-container .nav-link-div:hover path {
  stroke: #3eb1c8 !important;
}

/* Icon Animation Color is changed with the path stroke option */
.nav-container .nav-link-div[active="true"] {
  color: #3EB1C8;
  font-weight: bold;
  border-bottom: 1px solid #3EB1C8;
}

.nav-container .nav-link-div[active="true"] path {
  stroke: #3EB1C8;
}

.nav-container .nav-link-div[active="false"] {
  color: darkslategray;
}

.nav-container .nav-link-div[active="false"] path {
  stroke: darkslategray;
}

.nav-container .nav-link-div[disabled] {
  color: rgb(157, 156, 156);
  cursor: help;
}

.nav-container .nav-link-div[disabled] path {
  stroke: rgb(157, 156, 156);
}

.nav-container .icon {
  align-self: center;
  margin: 0 5px;
}

.nav-container .dropdown-toggle::after {
  display: none;
}

@media (min-width: 1600px) {
  .tools-holder {
    padding: 0 4rem;
  }
}

.add-workflow-button {
  height: 40px;
  width: 40px;
  border-radius: 18px;
  margin: 0 20px;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-workflow-button.small {
  height: 25px;
  width: 25px;
  margin: 0;
}

.add-workflow-button.small i {
  font-size: 12px;
}

@media (max-width: 1000px) {
  .add-workflow-button {
    height: 40px;
    width: 40px;
    border-radius: 18px;
    margin: 4px auto;
    outline: none;
    border: none;
  }
}

.workflows-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.custom-users {
  margin-top: 4px;
  /* border: 1px solid red; */
}

.tools-grid {
  display: flex;
  flex-wrap: wrap;
  align-content: space-evenly;
  justify-content: space-evenly;
  grid-gap: 10px;
  /* border: 1px solid green; */
}

.tool-holder {
  display: block;
  max-width: 500px;
  width: 100%;
  margin-top: 20px;
  /* border: 1px solid blue; */
}

.tool-holder-parameter {
  display: block;
  max-width: 680px;
  width: 100%;
  width: min(100%, 680px);
  margin-top: 20px;
}

#netsim.toolTab.flexColMedium {
  flex-direction: row;
  align-items: flex-start;
}

@media screen and (max-width: 1110px) {
  #netsim.toolTab .parametersWrapper {
    width: 280px;
  }
}

@media screen and (max-width: 1020px) {
  #netsim.toolTab .parametersWrapper {
    width: 100%;
    max-width: 400px;
  }

  #netsim.toolTab.flexColMedium {
    flex-direction: column;
    align-items: center;
  }
}

#netex .tab-content {
  border-top: 1px solid darkgray;
}

#netex .nav-tabs {
  border-bottom: 0;
}

#netex .nav-item>button {
  position: relative;
}

#netex .nav-item>button[aria-selected="true"]::after {
  content: "";
  position: absolute;
  z-index: 10;
  bottom: -1px;
  width: 100%;
  height: 1px;
  background-color: white;
  left: 0;
}

#netex .tool-holder-parameter,
#netsim .tool-holder-parameter {
  width: 100%;
  max-width: unset;
}


#netsim .tool-holder-parameter .slider {
  width: 100%;
  overflow: visible;
}

#netsim .tool-holder-parameter .slider .slide-items {
  width: 100%;
  flex-wrap: nowrap;
}

#netsim .parameter-toolbar {
  margin-bottom: -3%;
}

#netsim .parameters-holder-results {
  overflow: hidden;
  max-width: 1000px;
  margin: 0 auto;
}

#netsim .tab-content .item {
  min-height: 400px;
  flex-basis: unset;
}

#netsim .tool-holder-parameter .slider .slide-items .item {
  width: 100%;
  flex-shrink: 0;
  flex-basis: unset;
}

/* #netsim .tool-holder-parameter .slider .slide-items .item div{
  width: 100%;
} */

#netsim .tool-holder-parameter .slider .slide-items .item div svg {
  width: 100%;
}

#netsim .tab-content>#netsim-tabs-tabpane-ri.tab-pane {
  display: none !important;
}

#netsim .tab-content>#netsim-tabs-tabpane-ri.tab-pane.active {
  display: flex !important;
}

@media screen and (min-width: 1900px) {

  #netsim .tool-holder-parameter,
  #netex .tool-holder-parameter {
    width: 100%;
    max-width: unset;
  }

  #netsim .parameters-holder-results {
    max-width: unset;
  }


  #netsim .tab-content .item {
    min-height: 400px;
    flex-basis: unset;
  }

  #netsim .parameter-toolbar {
    display: none;
  }

  #netsim .slider {
    width: 100%;
    overflow: visible;
  }

  #netsim .tool-holder-parameter .slider .slide-items .item {
    width: unset;
    flex-shrink: unset;
  }

  #netsim .slide-items {
    width: 100%;
    justify-content: center;
    gap: 10px;
    transform: none !important;
  }


  svg[width="640"] {
    margin-left: auto;
    margin-right: auto;
  }
}