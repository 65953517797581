.parameters-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  box-shadow: 2px 2px 4px gray;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 100px;
  border: 1px solid lightgray;
  max-width: 500px;
}

.parameters-holder ul[role="list"] {
  width: 100%;
}

.parameters-holder ul[role="list"] .nodeTree {
  max-width: unset;
}

.netex-parameters-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  box-shadow: 2px 2px 4px gray;
  border-radius: 8px;
  margin-bottom: 15px;
  border: 1px solid lightgray;
  min-height: 580px;
  min-width: 500px;
}

.xPlot-holder {
  display: flex;
  margin-top: 20px;
  width: 100%;
  min-width: 300px;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
  box-shadow: 2px 2px 4px gray;
  border-radius: 8px;
  margin-bottom: 15px;
  border: 1px solid lightgray;
  overflow: auto;
  position: relative;
  max-width: 500px;
}

.network-tool .xPlot-holder {
  max-width: unset;
}

.network-tool .xPlot-holder.xPlot-holder-result {
  max-width: 400px;
}

.network-tool .toolResultWrapper {
  flex-direction: column;
}

.network-tool .xPlot-holder .xPlot-holder-content {
  padding: 8px;
  padding-top: 12px;
}

.xplot-image>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.xplot-image .adjustPositionNetex {
  margin-bottom: 40px;
}

/* @media screen and (max-width: 1660px) {
  .xplot-image .adjustPositionNetex {
    margin-top: -80px;
    margin-bottom: -15px;
  }
} */

.xplot-image>div style+div {
  transform: scale(1.1);
}

.xPlot-holder::before {
  content: "";
  padding-top: 100%;
  position: relative;
  z-index: 1;
  display: none;
}

.network-tool .xPlot-holder-cross[data-empty='true']::before,
.xPlot-holder[data-empty='true']::before {
  display: none;
}

@media screen and (max-width: 1500px) {
  .xPlot-holder {
    max-width: 500px;
  }
}

.xPlot-holder .xPlot-holder-content {
  z-index: 2;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1430px) {
  .network-tool .toolResultWrapper {
    flex-direction: row;
  }

  .xPlot-holder::before {
    display: block;
  }

  .xPlot-holder .xPlot-holder-content {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 1500px) {
  .xplot-image>div style+div {
    transform: scale(0.85) !important;
  }
}

@media screen and (max-width: 1600px) {
  .xplot-image>div style+div {
    transform: scale(0.85);
  }
}

@media screen and (max-width: 1800px) {
  .xplot-image>div style+div {
    transform: scale(0.9);
  }
}

@media screen and (min-width: 1800px) {
  .network-tool .xPlot-holder-cross {
    max-width: 900px;
  }
}

@media screen and (min-width: 2000px) {
  .network-tool .xPlot-holder.xPlot-holder-result {
    max-width: unset;
  }
}

@media screen and (max-width: 1280px) {
  .xPlot-holder {
    min-width: unset;
    /* width: 100%; */
  }
}

@media screen and (max-width: 500px) {
  .parameters-holder {
    min-width: unset;
    width: 100%;
    overflow: hidden;
  }

  .netex-parameters-holder {
    min-width: unset;
    width: 100%;
  }
}

.result-label {
  margin-left: 4px;
  align-content: left;
  margin-right: 4px;
  font-size: large;
  text-align: left;
}

.result {
  margin-left: 4px;
  align-content: left;
  margin-right: 4px;
  font-size: large;
  font-style: italic;
  font-weight: bold;
  color: #032e74;
}

.parameters-holder-parameter {
  justify-content: space-between;
  display: flex;
}

.parameters-holder .label {
  margin-left: 4px;
  align-content: left;
  margin-right: 4px;
}

.parameters-holder .number {
  font-size: 14px;
  width: 45px;
  margin: 1px 5px 1px 5px;
  border: 1px solid #2ea591;
  border-radius: 4px;
  padding: 2px;
}

.figure {
  display: flex;
  align-self: center;
  width: 600px;
  height: 410px;
  padding: 8px;
  margin: 4px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background-color: rgb(229, 236, 238);
  /* border: 1px solid red; */
}

.title {
  margin-left: 4px;
  margin-bottom: 10px;
  font-size: xx-large;
  font-weight: 600;
  color: #2c98ad;
}

.subtitle {
  font-size: x-large;
  color: #3EB1C8;
  margin-top: 2px;
  margin-left: 4px;
  text-align: left;
}

.subtitle[disabled] {
  font-size: x-large;
  color: #9da3a2;
  margin-top: 2px;
  margin-left: 4px;
}

.no-results {
  font-size: large;
  color: #3EB1C8;
  margin-top: 10%;
  margin-left: 4px;
}

.no-results-netex {
  font-size: large;
  color: #3EB1C8;
  margin-top: 10%;
  margin-bottom: 10%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 97.6%;
}

.parameter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px auto 10px 14px;
  justify-content: left;
  width: 96%;
  /* border: 1px solid green; */
}

.parameter-tree {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px auto 10px 2px;
  justify-content: left;
  width: 100%;
  /* border: 1px solid green; */
}

.parameters-holder .parameter .inline-parameters {
  display: inline-flex;
  max-width: 360px;
  width: 100%;
  width: min(100%, 360px);
  padding-right: 4px;
  /* border: 1px solid red; */
}

.parameters-holder .parameter .inline-parameters .bounded-label {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 0;
  text-align: left;
  /* border: 1px solid #2ea591; */
}

.parameters-holder .parameter .inline-parameters .icons-holder {
  display: inline-flex;
  margin-left: auto;
  padding-left: 10px;
  /* border: 1px solid green; */
}

.parameters-holder .parameter .inline-parameters .icon-box {
  align-self: center;
}

.horizontal-slider-box {
  width: 100%;
  align-self: center;
  padding-left: 6px;
  padding-right: 20px;
}

.icon {
  align-self: center;
}

.icon-green:hover {
  color: #007a53;
}

.icon-green:hover path {
  stroke: #007a53;
}

.icon-grey:hover {
  color: gray;
}

.icon-grey:hover path {
  stroke: gray;
}

.icon-red:hover {
  color: red;
}

.icon-red:hover path {
  stroke: red;
}

.icon-blue:hover {
  color: blue;
}

.icon-blue:hover path {
  stroke: blue;
}

.parameters-holder .parameter .filename {
  width: 100%;
  border-radius: 4px;
  outline: none;
  padding-left: 4px;
  border: 1px solid #2ea591;
}

.parameters-holder .parameter .label .number {
  width: 50px;
}

.checkbox-holder-parameter {
  display: flex;
  align-content: flex-start;
  align-self: start;
  margin-left: 6px;
  flex-direction: column;
  margin-bottom: 2%;
  margin-top: 2%;
  width: 97%;
}

.checkbox-holder-parameter .custom-control {
  display: flex;
  align-self: auto;
  justify-content: space-between;
  align-items: center;
}

.checkbox-holder-parameter .custom-control-label {
  align-self: start;
  border: none;
  outline: none;
  box-shadow: none;
  /* border: 1px solid yellowgreen; */
}

.checkbox-holder-parameter .custom-control-label:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.dropdown {
  display: flex;
  align-content: flex-start;
  align-self: start;
}

.number-parameter {
  font-size: 14px;
  width: 57%;
  margin: 0;
  border: 1px solid #2ea591;
  border-radius: 4px;
  padding: 2px;
}

.number-parameter2 {
  font-size: 14px;
  width: 100%;
  margin: 0;
  border: 1px solid #2ea591;
  border-radius: 4px;
  padding: 2px;
}

.text-parameter {
  font-size: 14px;
  width: 100%;
  margin: 0;
  border: 1px solid #2ea591;
  border-radius: 4px;
  padding: 4px;
}

.parameter_btn {
  color: #68d2df !important;
  text-align: start;
  margin-left: 4px;
}

.parameter_btn-left {
  padding-left: 0;
  text-align: start;
}

.card-body-parameter {
  padding: 0;
}

.margin-up-down {
  margin: 2% 0 3% 0;
}

.label-parameter::after {
  width: 1.3rem;
  height: 1.3rem;
}

.label-parameter::before {
  width: 1.3rem;
  height: 1.3rem;
}

.custom-parameter {
  align-self: auto !important;
  padding-left: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #17a2b8 !important;
}

.custom-btn {
  line-height: 1.2;
  box-shadow: none !important;
}

.custom-btn:focus {
  box-shadow: none !important;
}

.row-parameter {
  text-align: start;
  margin-bottom: 1%;
  padding-left: 8%;
}

.custom-btn-icon {
  font-size: smaller;
}

.parameter_btn:hover {
  color: #17a2b8;
}

.nav-link {
  color: #777978;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #57a6a2;
}

.parameters-holder-results {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px;
  box-shadow: 2px 2px 4px grey;
  border-radius: 8px;
  margin-bottom: 15px;
  border: 1px solid lightgray;
  min-height: 490px;
  overflow: auto;
}

.tab-content {
  border-left: 1px solid darkgray;
  border-right: 1px solid darkgray;
  border-bottom: 1px solid darkgray;
  margin-left: 1%;
  padding-right: 1%;
  margin-bottom: 1%;
  min-width: 97.6%;
  min-height: 490px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  /* border-radius: 7px; */
}

.nav-tabs {
  margin-bottom: 0;
  border-bottom: 1px solid darkgray;
  width: 97.6%;
  margin-left: 1%;
}

.nav-tabs .nav-link.active {
  background-color: #fff;
  border-color: darkgray darkgray #fff;
  color: #777978 !important;
  outline: none;
}

.tooltip-msg {
  background-color: #5a5a5a;
  padding: 7px;
  opacity: 1;
  max-width: 400px;
  border-radius: 7px;
}

.inline-holder-parameter {
  display: inline-flex;
  width: 100%;
  justify-content: left;
  vertical-align: middle;
  margin: 4px 0;
}

.right-holder-parameter {
  display: flex;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 18px;
  gap: 6px;
}

.dotloading {
  display: inline-block;
  font-size: x-large;
  color: #57a6a2;
  margin-left: 2px;
  clip-path: inset(0 3ch 0 0);
  animation: l 0.5s steps(6) infinite;
}

.expdotloading {
  display: inline-block;
  font-size: xx-large;
  color: #57a6a2;
  margin-left: 2px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.parameter-toggle {
  z-index: 100;
}

.parameter-toolbar {
  display: flex;
  justify-content: center;
  margin-top: 1%;
  margin-bottom: -7%;
}

.slider {
  width: 640px;
  overflow: hidden;
}

.slide-items {
  display: flex;
  width: 1280px;
  flex-wrap: wrap;
  transition: all 300ms;
}

.item {
  flex-basis: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicators {
  display: flex;
  list-style: none;
  margin-top: 10px;
  justify-content: center;
}

.indicators li {
  height: 10px;
  width: 10px;
  background: #ddd;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  opacity: 0.7;
}

.indicators li:hover {
  opacity: 1;
}

.indicators li.active {
  background: #000;
}

.newExperimentalWindow {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

div::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px gray; */
  border-radius: 8px;
}

div::-webkit-scrollbar-thumb {
  background-color: #a5d9e1;
  border-radius: 8px;
}

div::-webkit-scrollbar {
  width: 0.5em;
  border-radius: 8px;
}

.expRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.expCol {
  position: relative;
  width: 100%;
  min-height: 1px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  padding: 1%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.expFile {
  width: 95%;
}

.custom-file-label {
  text-align: start;
}

.custom-file-label::after {
  padding: 0.375rem 1.92rem;
}

.exper-data-hr {
  margin-bottom: 2%;
}

/* CAROUSEL WRAPPER */
.carouselWrapper {
  width: 100%;
}