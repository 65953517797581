body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-control {
  margin-bottom: 0;
}

.select-wrapper {
  margin: 6px 0 15px 0;
  width: 100%;
}

.select-title {
  margin-bottom: 5px;
  text-align: left;
  color: gray;
  font-size: 15px;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
}
select::-ms-expand {
  display: none;
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  width: 100%;
  min-width: 15ch;
  max-width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.25em;
  padding: 0.5em 0.8em;
  font-size: 15px;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}

select, .select::after {
  grid-area: select;
}

.select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}


/* CUSTOM BOOTSTRAP CSS */

/* Bootstrap button */
.btn-primary {
  background-color: #3EB1C8 !important;
  border: 1px solid transparent;
  box-shadow: none !important;
}

button:disabled {
  color: #616161 !important;
  background-color: #cccccc !important;
  pointer-events: none !important;
  border-color: #aeaeae !important;
}

.btn-primary:focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 1px #3EB1C8 !important;
  opacity: 0.8;
}

.btn-primary:hover {
  background-color: white !important;
  color: #3EB1C8 !important;
  border: 1px solid #3EB1C8 !important;
}

.btn-outline-primary {
  color: #3EB1C8;
  background-color: transparent;
  border-color: #3EB1C8;
}

.btn-outline-primary:hover,
.btn-outline-primary.active {
  color: #fff;
  background-color: #3EB1C8 !important;
  border-color: #3EB1C8 !important;
}

/* Bootstrap nav */
.navbar-nav {
  gap: 5px;
}

/* FIX CSS FOR IMAGE FOLDER */
.imageFolder .custom-checkbox {
  padding-left: 28px;
}

.imageFolder .label-parameter::before,
.imageFolder .label-parameter::after {
  left: 1px;
  transform: scale(0.85) translateY(-3px);
}